<template>
  <div class="auth-footer-btn d-flex justify-content-center">
    <b-button variant="google" @click="onSocialAuth('google')">
      <Icon icon="fe:google" />
    </b-button>
    <b-button variant="light" @click="onSocialAuth('paypal')">
      <Icon icon="logos:paypal" />
    </b-button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { BButton } from "bootstrap-vue";
import { apiErrorHandler } from "@/services/utils/util";
import { mapActions } from "vuex";
export default {
  components: {
    Icon,
    BButton,
  },
  methods: {
    ...mapActions({
      socialLogin: "auth/socialLogin",
    }),
    async onSocialAuth(provider) {
      try {
        const data = await this.$auth.authenticate(provider);
        await this.socialLogin({ provider, data });
      } catch (e) {
        console.error(e);
        apiErrorHandler(e);
      }
    },
  },
};
</script>

<style></style>

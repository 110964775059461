<template>
  <auth-master-layout>
    <b-card-text class="mb-2">
      Please sign in and start the adventure
    </b-card-text>

    <b-link
      :to="{ name: 'channels' }"
      class="font-weight-bold d-block text-nowrap"
    >
      <feather-icon icon="ArrowLeftIcon" size="19" />
      Go back to channels
    </b-link>

    <!-- form -->
    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- email -->
        <b-form-group label="Email" label-for="login-email">
          <validation-provider
            #default="{ errors }"
            name="email "
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="payload.email"
              :state="errors.length > 0 ? false : null"
              name="login-email"
              placeholder="john@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <div class="d-flex flex-column-reverse">
            <validation-provider
              #default="{ errors }"
              :name="'password' + ' '"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="payload.password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label>
              <b-link to="/forgot-password">
                <small>Forgot Password?</small>
              </b-link>
            </div>
          </div>
        </b-form-group>

        <!-- checkbox -->
        <b-form-group>
          <b-form-checkbox
            id="remember-me"
            v-model="payload.remember_me"
            name="checkbox-1"
          >
            Remember Me
          </b-form-checkbox>
        </b-form-group>

        <!-- submit buttons -->
        <b-button
          type="submit"
          variant="primary"
          block
          @click="onSubmit"
          :disabled="loading || isAdmin"
        >
          Sign in
        </b-button>
      </b-form>
    </validation-observer>

    <b-card-text class="text-center mt-2">
      <span>New to our platform? </span>
      <b-link to="/registration">
        <span>&nbsp;Create an account</span>
      </b-link>
    </b-card-text>

    <b-card-text class="text-center mt-2">
      <b-link to="/resend-verification-email">
        <span>Resend verification email</span>
      </b-link>
    </b-card-text>

    <!-- divider -->
    <div class="divider my-2">
      <div class="divider-text">or</div>
    </div>

    <!-- social buttons -->
    <social-login-buttons></social-login-buttons>
  </auth-master-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCardText,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";
import { email, required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import AuthMasterLayout from "@/views/auth/AuthMasterLayout";
import SocialLoginButtons from "@/layouts/components/buttons/SocialLoginButtons.vue";

export default {
  components: {
    AuthMasterLayout,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    SocialLoginButtons,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      payload: {
        password: "",
        email: "",
        remember_me: false,
      },
      required,
      email,
    };
  },
  computed: {
    loading() {
      return store.getters["auth/isAuthLoading"];
    },

    isAdmin() {
      return store.getters["auth/isAdmin"];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.loginValidation.validate();
      if (success) {
        this.$store.dispatch("auth/login", this.payload);
      }
    },
  },
};
</script>
